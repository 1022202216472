import React from 'react';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';




const Home = () => {

  return(
    <Paper
          sx={{height:"80%", width:"80%", margin:"auto", marginTop:"5em"}}
        >

          <Typography
            component='div'
            variant="body1"
            sx={{textAlign:'left', fontSize:"18px", padding:"2em", margin:0, width:"90%"}}
            paragraph={true}
          >

            <Box sx={{fontWeight:"600", display:"inline"}}> Swiss Flow Dent GmbH</Box> is a research-oriented and innovative company with a focus on dentistry and especially dental implant development, as well as sales on the national and international market in cooperation with dental implant developers with the purpose of manufacturing atraumatic and risk-minimizing dental implant constructions with higher stability and longevity, as well as their marketing, licensing and patent application.

          </Typography>
    </Paper>
          
          

  )
}    

export default Home;