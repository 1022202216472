import React from 'react';
import { AppBar, styled, Toolbar, Typography, IconButton, Badge, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import { StyledAppBar } from './StyledComponents'

const PREFIX = 'Header';
const classes = {
  root: `${PREFIX}-root`,
  menuItems: `${PREFIX}-menuItems`,
  discount: `${PREFIX}-discount`,
  cart: `${PREFIX}-cart`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    margin: 'auto',
    overflow: 'hidden',
    backgroundColor: "white"
  },
  [`& .${classes.menuItems}`]: {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export default function ButtonAppBar() {
  return (
    <div>
      <Root position="static" className={classes.root}>
        <Toolbar sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'center' }}>

          {/* Logo and Title */}
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', mb: { xs: 1, md: 0 } }}>
            <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <LocalHospitalIcon sx={{ fontSize: "48px", color: "primary.dark", backgroundColor: "white" }} />
              <Typography variant="h4" sx={{ textAlign: 'left', color: "primary.dark", ml: 1 }}>
                SWISS FLOW DENT Ltd.
              </Typography>
            </Link>
          </Box>

          {/* Navigation */}
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            <Typography variant="body1" component={Link} to="/home" className={classes.menuItems} >
              Home
            </Typography>
            <Typography variant="body1" component={Link} to="/our_team" className={classes.menuItems} >
              Our Team
            </Typography>
            <Typography variant="body1" component={Link} to="/contact" className={classes.menuItems} >
              Contact
            </Typography>
          </Box>

        </Toolbar>
      </Root>
    </div>
  );
}
