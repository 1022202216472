// reducers/index.js

import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import productsReducer from './productsReducer';

const rootReducer = combineReducers({
  shoppingCart: cartReducer,
  products: productsReducer
  // Add other reducers here if needed
});

export default rootReducer;
