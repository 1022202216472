import React from "react";
import { Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducersBundle from './reducers';

import { App } from './components';

import Home from './components/ui/Home';
import Header from './components/ui/Header';
import Team from './components/ui/Team';
import Contacts from './components/ui/Contacts';
import { PrivateRoute } from "./PrivateRoute";

const store = createStore(
  reducersBundle,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const AppRoutes = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <App>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/our_team" element={<Team />} />
            <Route path="/contact" element={<Contacts />} />
            
          </Routes>
        </App>
      </HashRouter>
    </Provider>
  );
};

export default AppRoutes;
