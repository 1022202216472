import React from 'react';
import { Grid, Paper, Box, CardMedia, CardContent, Typography, CardActions, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import TeamMember from './TeamMember';

const Team = () => {
  return (
    <Paper
      sx={{height:"80%", width:"80%", margin:"auto", marginTop:"5em"}}
    >
      <Box sx={{ paddingTop:"2em", display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Meet Our Team of Specialists
        </Typography>
      </Box>

      <Box sx={{ paddingTop:"2em", display: 'flex', justifyContent: 'left', width: '100%'}}>
        <Typography variant="body1" sx={{ paddingLeft:"2em" , fontSize:"15px" }}>
          At the heart of our organization lies a diverse team of professionals, each a master in their respective field. Our team members are not just experts; they are enthusiastic pioneers, constantly pushing the boundaries of innovation and excellence. They bring a wealth of knowledge, experience, and unwavering dedication to their craft, ensuring that every project we undertake is handled with utmost precision and care.
        </Typography>
      </Box>

      
      <TeamMember
        key={"lp"}
        name="Dr. Ljubomir Petkov"
        image="/ljubo_team.png"
        description="Dr. Ljubomir Petkov is a distinguished dentist with over three decades of extensive experience in implantology, endodontics, and oral and maxillofacial surgery. Having led dental research, collaborated on innovative implant designs, and practiced in prestigious institutions across Europe, Dr. Ljubomir Petkov combines clinical excellence with a strong focus on aesthetic medicine and cutting-edge dental technology."
        position="SWISS FLOW DENT Ltd. / CEO and Co-founder"
      />                        
      <TeamMember
        key={"mm"}
        name="Dr. Monika Ritter"
        image="/monika_team.png"
        description="With a multifaceted educational background in dentistry and dental technology from the University of Munich LMU, Dr. Monika Ritter has demonstrated exceptional academic and professional achievements. As the CEO and co-founder of SwissFlowDent GmbH and a collaborator in cutting-edge dental implantology and laser technology applications, Dr. Monika Ritter brings a unique blend of medical management, innovative dental practices, and entrepreneurial leadership to the forefront of dental healthcare."
        position="SWISS FLOW DENT Ltd. / CEO and Co-founder"
      />                        
      <TeamMember
        key={"jk"}
        name="Prof. Julia Kamenova"
        image="/julia_team.png"
        description="Prof. Julia Kamenova is a highly esteemed figure in the field of dentistry, renowned for her extensive research and contributions to prosthodontics and laser dentistry. As an active member of various international dental associations and a respected educator, she has played a pivotal role in advancing dental sciences, notably in the evaluation of dental alloys and laser therapy applications."
        position="SWISS FLOW DENT Ltd. / Laser Therapy Expert"
      />                        
      <TeamMember
        key={"pk"}
        name="Petar Karaivanov"
        image="/petar_team.png"
        description="A seasoned full-stack developer with over a decade of experience, Petar Karaivanov has demonstrated exceptional skill in backend architecture, distributed systems, and real-time data processing. Founder of ProCode, a Munich-based IT firm, they have a strong track record in developing comprehensive front and back-end applications, with a special focus on data analysis and AI."
        position="SWISS FLOW DENT Ltd. / IT Specialist"
      />                        
        
      </Paper>
  );
};

export default Team;
