import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

function TeamMember({ name, image, description, position }) {
  return (
    <Paper sx={{ my: 2, overflow: 'hidden' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item md={4} sm={4} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={image}
            alt={name}
            style={{
              maxWidth: '80%', // Adjust as per your requirement
              maxHeight: '80%', // Adjust as per your requirement
              objectFit: 'cover',
              margin: '1em' // Provides some space around the image
            }}
          />
        </Grid>

        <Grid item md={8} sm={8} xs={12}>
          <Paper
            elevation={0}
            sx={{ backgroundColor: 'primary.dark', p: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Typography variant="h6" sx={{ paddingLeft:"3em", textAlign: 'left', color: 'white' }}>
              {name}
            </Typography>
            <Typography variant="body1" sx={{paddingLeft:"1em", textAlign: 'left', color: 'white' }}>
              {description}
            </Typography>
            <Typography variant="body2" sx={{ paddingLeft:"4em", textAlign: 'left', color: 'white', fontWeight: 'bold' }}>
              {position}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TeamMember;
