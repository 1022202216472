export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';

export const updateCart = (data) => ({
  type: UPDATE_CART,
  payload: data,
});

export const updateProducts = (data) => ({
  type: UPDATE_PRODUCTS,
  payload: data,
});
