// reducers/scoreboardReducer.js

import {
  UPDATE_PRODUCTS,
} from '../actions';

const initialState = []

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS:
      return [
        ...action.payload
        ]
    default:
      return state;
  }
};

export default cartReducer;
