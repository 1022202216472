// reducers/cartReducer.js

import { UPDATE_CART } from '../actions';

// Function to get the initial cart state from localStorage
const getInitialState = () => {
  const savedCart = localStorage.getItem('shoppingCart');
  if (savedCart) {
    return JSON.parse(savedCart);
  }
  return {
    items: [],
    total_count: 0
  };
};

const initialState = getInitialState();

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default cartReducer;
