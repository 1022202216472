import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';


const Contacts = () => {

  return (
    <Paper
          sx={{height:"80%", width:"80%", margin:"auto", marginTop:"5em"}}
        >
          <Box
            sx={{height:"1em", width:"1%", paddingTop:"1em"}}
          >

          </Box>
          <Typography
            variant="body1"
            sx={{textAlign:'left', fontWeight:"600", fontStyle:"italic", fontSize:"15px", paddingLeft:"1em"}}
          >
            Address:
          </Typography>


           <Typography
            variant="body2"
            sx={{textAlign:'left', fontSize:"15px", paddingLeft:"2em"}}
          >
            Swiss Flow Dent GmbH
          </Typography>
          <Typography
            variant="body2"
            sx={{textAlign:'left', fontSize:"15px", paddingLeft:"2em"}}
          >
            Forchstrasse 127
          </Typography>

          <Typography
            variant="body2"
            sx={{textAlign:'left', fontSize:"15px", paddingLeft:"2em"}}
          >
            8032, Zurich
          </Typography>
          <Typography
            variant="body2"
            sx={{textAlign:'left', fontSize:"15px", paddingLeft:"2em"}}
          >
            Switzerland
          </Typography>

          <Typography
            variant="body1"
            sx={{textAlign:'left', fontWeight:"600", fontStyle:"italic", fontSize:"15px", paddingLeft:"1em"}}
          >
            e-mail:
          </Typography>
          <Typography
            variant="body2"
            sx={{textAlign:'left', fontSize:"15px", paddingLeft:"2em"}}
          >
            swiss.flow.dent@gmail.com
          </Typography>
          <Box
            sx={{height:"1em", width:"1%", paddingTop:"1em"}}
          >
          </Box>

        </Paper>
  );
};

export default Contacts;
