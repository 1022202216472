import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './ui/Header';
// import { DataProvider } from './DataProvider';
// import { createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { updateProducts } from '../actions';
import '@fontsource/roboto';


const theme = createTheme({
	palette: {
        background:{
            default: "#ccc"
        },
    	primary: {
    		main: '#DC1200',
    		light: '##FF4433',
    		dark: '#A30E00'
    	},
    	secondary: {
    		main: '#D6FFED',
    		light: '#EBFFF6',
    		dark: '#ADFFDA'
    	},
    	warning:{
    		main: '#FFD6DA',
    		light: '#FFEBED',
    		dark: '#FFC2C8'
    	},
    	extras:{
    		main: '#EDABC8',
    		light: '#F8DDE9',
    		dark: '#E689B1'
    	}

  	},
    typography: {
        fontFamily: 'Roboto',
        fontSize: 12,

    }
})

export const App = (props) => {
	const dispatch = useDispatch();
 	const location = useLocation();

 	useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://wichtel-bg.com/getItems', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                if(data.length>0){
                	dispatch(updateProducts(data));	
                }
                
            } catch (error) {
                console.error("Fetching error: ", error);
                // Handle the error appropriately
                // You might want to dispatch a failure action here
            }
        };

        fetchData();
    }, [dispatch, location]);

	return(
		<ThemeProvider theme={theme}>
			<div className="app">
		        { props.children }
	    	</div>
	    </ThemeProvider>
	)

}
